<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              :to="{
                name: 'PointList',
              }"
            >{{ "點數新增紀錄" }}
            </b-breadcrumb-item>
            <b-breadcrumb-item
              :to="{
                name: 'PointList',
              }"
            >{{ "點數列表" }}
            </b-breadcrumb-item>
            <b-breadcrumb-item active
            >{{
                isReadOnly ? "查看點數" : isEditing ? "編輯點數" : "新增點數"
              }}
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-overlay :show="showLoading">
          <b-card>
            <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
              <h4 class="col-12 col-xl-6 mb-2 mb-xl-0 font-weight-bold">
                {{
                  isReadOnly ? "查看點數" : isEditing ? "編輯點數" : "新增點數"
                }}
              </h4>
              <div class="col-12 col-xl-8 mt-3">
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="通路"
                >
                  <b-form-select
                    v-model="merchantId"
                    :options="merchants"
                  ></b-form-select>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="會員 id"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    v-model="customerInfo"
                    disabled
                  ></b-form-input>
                  <PointAddCustomer
                    :customerId="point.customer_id"
                    @added="selectedCustomer"
                  ></PointAddCustomer>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="訂單 id"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    v-model="point.order_id"
                    :disabled=isReadOnly
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="分店 id"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    v-model="point.branch_id"
                    :disabled=isReadOnly
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="點數"
                >

                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    v-model="point.point"
                    :disabled=isReadOnly
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="備註"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    v-model="point.memo"
                    :disabled=isReadOnly
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="開始時間"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    v-model="point.start_at"
                    :disabled=isReadOnly
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="過期時間"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    v-model="point.expired_at"
                    :disabled=isReadOnly
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="已用點數"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    v-model="point.used_point"
                    :disabled=isReadOnly
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="活動 id"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    v-model="point.event_id"
                    :disabled=isReadOnly
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="source type"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    v-model="point.source_type"
                    :disabled=isReadOnly
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="source id"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    v-model="point.source_id"
                    :disabled=isReadOnly
                  ></b-form-input>
                </b-form-group>


              </div>
            </div>
            <div class="d-flex justify-content-center">
              <b-button
                class="mr-3"
                @click="handleCancel"
                variant="outline-danger"
              >返回
              </b-button>
              <b-button
                v-if="!isReadOnly"
                @click="handleSubmit"
                variant="success"
              >儲存
              </b-button
              >
            </div>
          </b-card>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import useVuelidate from "@vuelidate/core";
import pointApi from "../../../apis/point";
import merchantApi from "../../../apis/merchant";
import PointAddCustomer from "@/pages/Dashboard/Point/PointAddCustomer.vue";

export default {
  components: { PointAddCustomer },

  setup: () => ({v$: useVuelidate()}),

  data() {
    return {
      merchantId: null,
      merchants: [{value: null, text: "請選擇"}],
      showLoading: false,
      isEditing: true,
      validationErrors: null,
      customerInfo: null,
      point: {
        customer_id: null,
        order_id: null,
        branch_id: null,
        point: null,
        memo: null,
        start_at: null,
        expired_at: null,
        used_point: null,
        event_id: null,
        source_type: null,
        source_id: null,

      },
    };
  },

  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),

    isReadOnly() {
      return this.$route.name === "PointView";
    },
  },
  mounted() {
    this.fetchMerchants();
    if (!this.$route.params.point_id) {
      this.clearPoint();
      this.isEditing = false;
    } else {
      this.fetchPoint();
      this.merchantId = this.$route.params.merchant_id
      this.isEditing = true;
    }
  },
  methods: {
    async fetchPoint() {
      this.showLoading = true;
      const response = await pointApi.getPoint(
        this.$route.params.merchant_id,
        this.$route.params.point_id
      );
      this.point = response.data.data;
      this.customerInfo = this.point.customer_name
      this.showLoading = false;
    },

    clearPoint() {
      this.point = {
        tag: null,
        outer_code: null,
        customers_count: 0,
      };
    },
    async handleSubmit() {
      try {
        if (this.isEditing) {
          let response = await pointApi.updatePoint(
            this.merchantId,
            this.point
          );
          if (response.status && response.status === 200) {
            this.$swal.fire({
              title: "更新成功",
              type: "success",
            });

            this.$router.push({name: "PointList"});
          }
        } else {
          let response = await pointApi.storePoint(this.merchantId, this.point);

          if (response.status && response.status >= 200) {
            this.$swal.fire({
              title: "新增成功",
              type: "success",
            });

            this.$router.push({name: "PointList"});
          }
        }
      } catch (error) {
        if (error.response.status === 422 && error.response.data.message) {
          this.validationErrors = error.response.data.message;
        } else {
          this.$swal.fire({
            title: this.isEditing ? "更新失敗" : "新增失敗",
            type: "error",
          });
        }
      }
    },
    selectedCustomer(value) {
      this.customerInfo = `${value.name}`
      this.point.customer_id = value.id
      this.$forceUpdate()
    },
    handleCancel() {
      this.$router.push({name: "PointList"});
    },
    async fetchMerchants() {
      const {data} = await merchantApi.list();

      this.merchants = [
        {value: null, text: "請選擇"},
        ...data.data.map((merchant) => {
          return {
            value: merchant.id,
            text: `${merchant.name} (${merchant.type_name})`,
          };
        }),
      ];
    },
  },
};
</script>
